@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&family=Oswald&family=Rubik:wght@500&family=Tilt+Neon&display=swap');

div{
    font-family: 'Nunito', sans-serif;
}

.carddiv:hover{
    border-bottom-right-radius: 100px 25px;
    border-bottom-left-radius: 100px 25px;
    overflow: hidden;
    /* padding: 3px; */
    border: 1px solid #fff;
    box-shadow: 0 0 10px #F97316;
    transform-origin: bottom left right;
    transition-duration: 500ms;
}