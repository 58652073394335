@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

nav {
    font-family: 'Oswald', sans-serif;
}

.topbar-right{
    transform-origin: top left;
    transform: skewX(25deg);
}

.topbar-left{
    transform-origin: bottom left;
    transform: skewX(25deg);
}

.active{
    color: #F97316;
}