@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.carouseltemporarybox{
    width: 100%;
    overflow: hidden;
    position: relative;

    .buttons{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        gap: 20px;
        bottom: 10vh;
        z-index: 2;

        @media screen and (max-width: 875px) {
            bottom: 5vh;
        }
        @media screen and (max-width: 600px) {
            bottom: 20px;
        }
        @media screen and (max-width: 450px) {
            bottom: 10px;
            gap: 10px;
        }

        .btn-icon{
            width: fit-content;
            height: fit-content;
            padding: 15px;
            font-size: 30px;
            color: white;
            border: 1px solid white;

            @media screen and (max-width: 600px) {
                font-size: 20px;
            }
            @media screen and (max-width: 450px) {
                font-size: 12px;
                padding: 7px;
            }
        }
    }

    .carousel{
        display: flex;
        transition: all 1s;
        
        .slide{
            position: relative;
            height: fit-content;
            max-height: 110vh;
            z-index: 1;

            img{
                min-width: 100vw;
                height: fit-content;
                z-index: 1;
            }

            .tagline{
                position: absolute;
                color: white;
                font-size: 75px;
                font-weight: 800;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                padding: 0 4vw 0 4vw;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.418);
                width: 100%;
                letter-spacing: 0.2rem;
                font-family: 'Montserrat', sans-serif;

                @media screen and (max-width: 1037px) {
                    font-size: 50px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 640px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 450px) {
                    font-size: 20px;
                }
            }
        }

    }
}